.blog-page-header {
  margin-top: 0px !important;
}

.blog-page-header-text {
  color: #fff;
  font-size: 2.7rem;
}

.blog-page-header-subtext {
  color: #fff;
  font-size: 1.35rem;
}

.featured-post-preview {
  /* background: #fff; */
  margin-top: 160px;
  /* border: 1px solid #eee; */
  /* box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
  margin-bottom: 50px;
}

.featured-post-preview:hover {
  cursor: pointer;
}

.featured-post-preview a:hover {
  text-decoration: none !important;
}

.featured-post-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 4px 0px 0px 4px;
}

.featured-post-body {
  padding: 20px 30px;
}

.featured-post-body h2 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
}

.featured-post-text {
  font-size: 1.1rem;
  margin-top: 15px;
  opacity: 0.6;
}

.featured-post-footer {
  margin-top: 30px;
}

.featured-post-footer img {
  width: 70%;
  border-radius: 100px;
  max-width: 50px;
}

.preview-author {
  margin-bottom: 0px;
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 2px;
}

.post-header h1, .post h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 60px;
  line-height: 3.7rem;
  margin-bottom: 25px;
}

.post-subheader {
  margin-top: 5px;
}

.post-author {
  margin-bottom: 0px;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 2px;
}

.post-author-picture {
  width: 70%;
  border-radius: 100px;
  max-width: 70px;
  margin-top: 4px;
}

.post-date-length {
  font-size: 1.1rem;
  opacity: 0.6;
}


.preview-date-length {
  opacity: 0.6;
}

.post-body {
  /* padding: 5px; */
}

.post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.post-body h2, .post h2 {
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2rem;
  margin-top: 15px;
  margin-bottom: 10px;
}

.post-text {
  font-size: 1rem;
  margin-bottom: 20px;
}

.post-footer img {
  width: 100%;
  border-radius: 50%;
  max-width: 50px;
}

.post-previews {
  margin-top: 30px;
}

.demo-container {
  padding: 100px 20px;
  background: linear-gradient(90deg, #232526 0%, #414345 100%);
  color: #fff;
  margin-top: 100px;
}

.demo-container h2 {
  font-weight: bold;
  font-size: 2.3rem;
}

.demo-container p {
  font-size: 1.3rem;
  margin-bottom: 50px;
}

.plan-cta {
  font-size: 1.2rem;
  padding: 10px 25px;
}


.post-header {
  /* color: #fff; */
  margin-bottom: -20px;
}

.post-cover-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.post-subheader {
  display: inline-block;
}

.markdown a {
  font-weight: bold;
  text-decoration: underline;
}

.markdown a:hover {
  color: #ff7f50;
  transition: 0.3s ease;
  text-decoration: none;
}

.markdown p {
  font-size: 1.2rem;
}


.markdown h2 {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 70px;
}

.markdown img {
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: block;
}

@media (max-width: 996px) {
  .featured-post-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .featured-post-preview {
    margin-top: 50px;
  }

  .featured-post-image {
    border-radius: 0px 0px 0px 0px;
  }

  .demo-container h2 {
    font-size: 2rem;
  }

  .demo-container p {
    font-size: 1.2rem;
  }
  
}

@media (max-width: 500px) {
  .blog-page-header-text {
    font-size: 2.3rem;
  }
  
  .blog-page-header-subtext {
    font-size: 1.1rem;
    margin-bottom: 100px;
  }

  .demo-container h2 {
    font-size: 1.7rem;
    margin-bottom: 10px;
  }

  .demo-container p {
    font-size: 0.9rem;
  }

  .post-cover-image {
    height: 300px;
    margin-bottom: 20px;
  }
}